





















































import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { TradeshowCompanyObject } from "@/types/interfaces";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import Spinners from "@/components/utilities/Spinners.vue";
import ExhibitorLogoCard from "@/components/shared/ExhibitorLogoCard.vue";
import ExhibitorResultCard from "@/components/shared/ExhibitorResultCard.vue";

const tradeshowStore = getModule(tradeshowVuexModule);

@Component({
    components: {
        Spinners,
        ExhibitorLogoCard,
        ExhibitorResultCard
    }
})
export default class FeaturedSection extends Vue {
    isLoading = true;
    featuredHeaderAlignment = "";

    get sponsorToLoad() {
        const res = this.$store.getters.getPageOptions("home").section;
        const sponsorType = res.featuredSolutionProvider.sponsorCategory;

        return sponsorType;
    }
    get tradeshowCompanies() {
        let companies = tradeshowStore.allTradeshowCompanies || [];

        const filteredSection = this.pageOptions;

        if (
            filteredSection.section?.featuredSolutionProvider.filteredforPromo
        ) {
            companies = companies.filter(
                (item: TradeshowCompanyObject) => item.forPromo
            );
        }

        return companies.sort(
            (cur: TradeshowCompanyObject, nxt: TradeshowCompanyObject) => {
                const curName = cur.companyName
                    ? cur.companyName
                    : cur.name
                    ? cur.name
                    : "";
                const nextName = nxt.companyName
                    ? nxt.companyName
                    : nxt.name
                    ? nxt.name
                    : "";

                return curName.localeCompare(nextName);
            }
        );
    }
    get cssVariables() {
        return this.$store.getters.cssVariables;
    }
    get headerAlignment(): string {
        return this.$store.getters.getPageOptions("home").section
            .featuredSolutionProvider.labelHeaderAlignment
            ? "text-center"
            : "";
    }
    get pageOptions() {
        return this.$store.getters.getPageOptions("home");
    }

    get pageConfig() {
        return this.$store.getters.getPageOptions("tradeshow");
    }
    get homeLabel() {
        return this.$store.getters.homeLabel;
    }

    get showDescriptions() {
        return this.pageOptions.section.featuredSolutionProvider
            .showDescriptions;
    }

    created() {
        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });
    }

    /**
     * methods
     */
    getImage(data: TradeshowCompanyObject) {
        let returnPath = data.imageLogo || data.images?.logo;
        if (!returnPath) {
            return (returnPath = `${process.env.BASE_URL}bg/${this.pageConfig.defaultLogo}`);
        } else {
            return returnPath;
        }
    }

    getImageAlt(data: TradeshowCompanyObject) {
        const hasImage = Boolean(data.imageLogo || data.images?.logo);

        return hasImage
            ? `${data.companyName} Logo`
            : `${this.$store.state.themeConfig.conferenceName} Logo`;
    }
}
